import {
  CorrectionReasonAction,
  CorrectionReasonField,
  ExpectedPaymentForm,
  InvoiceReviewerStatus,
  InvoiceType,
  ReceiptPrintingStatus,
  ReviewStatus,
  TaxProcedure,
  TradingDocument,
  TradingDocumentKind,
  TradingDocumentNotificationAction,
  TradingDocumentNotificationStatus,
  TradingDocumentPaymentKind,
  TradingDocumentPaymentMode,
  TradingDocumentPaymentProvider,
  TradingDocumentPaymentStatus,
  TradingDocumentPaymentType,
  TradingDocumentPdfProcessingStatus,
  TradingDocumentStatus,
  TradingDocumentType,
  ViesStatus,
} from "api/trading-documents/models";
import changesRequestedIcon from "assets/images/ruleOrange.svg";
import acceptedIcon from "assets/images/checkTeal.svg";
import awaitingIcon from "assets/images/radioButtonPartial.svg";
import { TagProps } from "components/miloDesignSystem/atoms/tag";
import { colorType } from "components/common/stateLabel";
import { dictToList, generateDictBasedOnFactoryAndEnum } from "utilities";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

const invoiceTypeDict: Record<InvoiceType, string> = {
  CORRECTION: "korekta",
  FINAL: "końcowa",
  ADVANCE: "zaliczkowa",
  PROFORMA: "proforma",
  PURCHASE: "zakupowa",
  SALES: "sprzedażowa",
};

const invoiceTypeOptions: { id: InvoiceType; name: string }[] = Object.entries(invoiceTypeDict).map(
  ([id, name]) => ({
    id: id as InvoiceType,
    name,
  }),
);

const invoiceStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzona",
  NEW: "Nowa",
  WORK_IN_PROGRESS: "Robocza",
};

const receiptStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzony",
  NEW: "Nowy",
  WORK_IN_PROGRESS: "Roboczy",
};

const tradingDocumentStatusVariant: Record<
  TradingDocumentStatus,
  Record<TradingDocumentType, { label: string; variant: TagProps<string>["variant"] }>
> = {
  CONFIRMED: {
    INVOICE: { label: invoiceStatusDict["CONFIRMED"], variant: "success" },
    RECEIPT: { label: receiptStatusDict["CONFIRMED"], variant: "success" },
  },
  NEW: {
    INVOICE: { label: invoiceStatusDict["NEW"], variant: "info" },
    RECEIPT: { label: receiptStatusDict["NEW"], variant: "info" },
  },
  WORK_IN_PROGRESS: {
    INVOICE: { label: invoiceStatusDict["WORK_IN_PROGRESS"], variant: "warning" },
    RECEIPT: { label: receiptStatusDict["WORK_IN_PROGRESS"], variant: "warning" },
  },
};

const viesStatusDict: Record<ViesStatus, string> = {
  ACTIVE: "Aktywny",
  INACTIVE: "Nieaktywny",
  UNREGISTERED: "Niezarejestrowany",
  NO_DATA: "Brak danych",
  NO_TAX_ID: "Brak NIP-u",
  INVALID: "Nieznany",
};

const invoicePaymentStatusDict: Record<TradingDocumentPaymentStatus, string> = {
  NOT_PAID: "Nieopłacona",
  PAID: "Opłacona",
  PARTIALLY_PAID: "Częściowo opłacona",
};

const invoicePaymentStatusOptions: {
  id: TradingDocumentPaymentStatus;
  name: string;
}[] = Object.entries(invoicePaymentStatusDict).map(([id, name]) => ({
  id: id as TradingDocumentPaymentStatus,
  name,
}));

const invoicePaymentStatusItems = dictToList(invoicePaymentStatusDict).map(({ key, value }) => ({
  text: value,
  type: MenuItemType.TEXT,
  value: key,
}));

const tradingDocumentStatusDict: Record<TradingDocumentStatus, string> = {
  CONFIRMED: "Zatwierdzony",
  NEW: "Nowy",
  WORK_IN_PROGRESS: "Roboczy",
};

const tradingDocumentStatusOptions: { id: TradingDocumentStatus; name: string }[] = Object.entries(
  tradingDocumentStatusDict,
).map(([id, name]) => ({ id: id as TradingDocumentStatus, name }));

const taxProcedureDict: Record<TaxProcedure, string> = {
  NA: "Nie dotyczy",
  OSS: "OSS",
  ISG: "Wewnątrzwspólnotowa Dostawa Towarów",
  DOMESTIC: "Dostawa krajowa",
  EXPORT: "Eksport",
};

const taxProcedureFilterOptions: {
  label: string;
  value: TaxProcedure;
}[] = Object.entries(taxProcedureDict).map(([procedure, label]) => ({
  label,
  value: procedure as TaxProcedure,
}));

const reviewStatusDict: Record<ReviewStatus, string> = {
  OPEN: "Otwarta",
  CLOSED: "Zamknięta",
};

const invoiceReviewerStatusDict: Record<InvoiceReviewerStatus, string> = {
  AWAITING: awaitingIcon,
  ACCEPTED: acceptedIcon,
  CHANGES_REQUESTED: changesRequestedIcon,
};

const tradingDocumentPaymentProviderDict: Record<TradingDocumentPaymentProvider, string> = {
  PAYPRO: "PayPro",
  P24: "Przelewy24",
  PAYU: "PayU",
  BLUE_MEDIA: "BlueMedia",
};

const tradingDocumentPaymentProviderOptions: {
  id: TradingDocumentPaymentProvider;
  name: string;
}[] = Object.entries(tradingDocumentPaymentProviderDict).map(([id, name]) => ({
  id: id as TradingDocumentPaymentProvider,
  name,
}));

const tradingDocumentPaymentTypeDict: Record<TradingDocumentPaymentType, string> = {
  CASH: "gotówka",
  ONLINE: "przelew",
};

const tradingDocumentPaymentTypeOptions: {
  id: TradingDocumentPaymentType;
  name: string;
}[] = Object.entries(tradingDocumentPaymentTypeDict).map(([id, name]) => ({
  id: id as TradingDocumentPaymentType,
  name,
}));

const tradingDocumentPaymentKindDict: Record<TradingDocumentPaymentKind, string> = {
  ADVANCE: "zaliczka",
  OTHER: "inne",
  PAYMENT: "płatność",
  REFUND: "zwrot",
};

const tradingDocumentPaymentKindOptions: {
  id: Omit<TradingDocumentPaymentKind, "OTHER">;
  name: string;
}[] = Object.entries(tradingDocumentPaymentKindDict)
  .filter(([id]) => id !== "OTHER")
  .map(([id, name]) => ({
    id: id as Omit<TradingDocumentPaymentKind, "OTHER">,
    name,
  }));

const expectedPaymentFormDict: Record<ExpectedPaymentForm, string> = {
  CASH: "Gotówka",
  ONLINE: "Przelew",
  "": "nie występuje na dokumencie",
};

const paymentMethodsOptions: {
  label: string;
  value: string;
}[] = Object.entries(expectedPaymentFormDict)
  .filter(([value]) => value !== "")
  .map(([value, label]) => ({
    label,
    value: (value as Omit<ExpectedPaymentForm, "">) as string,
  }));

const tradingDocumentPaymentModeShortcutsDict: Record<TradingDocumentPaymentMode, string> = {
  AUTO: "automat.",
  MANUAL: "manual.",
};

const tradingDocumentPaymentKindForPurchaseInvoiceDict: Record<
  TradingDocumentPaymentKind,
  string
> = {
  ADVANCE: "zal.",
  PAYMENT: "płt.",
  REFUND: "zwr.",
  OTHER: "inn.",
};

const tradingDocumentNotificationActionDict: Record<TradingDocumentNotificationAction, string> = {
  STANDARD: "wysłano fakturę",
  CORRECTION: "wysłano korektę",
  CORRECTION_DUPLICATE: "wysłano duplikat korekty",
  DUPLICATE: "wysłano duplikat",
};

const tradingDocumentNotificationStatusDict: Record<TradingDocumentNotificationStatus, string> = {
  OPENED: "Odczytana",
  SENT: "Wysłana",
  DECLINED: "Błąd wysyłki",
  CLICKED_LINK: "Odczytana",
};

const receiptPrintingStatusDict: Record<
  ReceiptPrintingStatus,
  { color: ColorPalette; label: string }
> = {
  NOT_STARTED: { color: "success600", label: "gotowy do druku" },
  IN_PROGRESS: { color: "deepPurple400", label: "drukuje się" },
  FINISHED: { color: "info500", label: "wydrukowano" },
  FAILED: { color: "danger500", label: "błąd drukowania" },
};

const invoiceProcessingStatusDict: Record<TradingDocumentPdfProcessingStatus, string> = {
  NEW: "Nowa",
  IN_PROGRESS: "W toku",
  DONE: "Gotowe",
  ACCEPTED: "Zaakceptowana",
  FAILED: "Niepowodzenie",
  REJECTED: "Odrzucono",
};

const invoiceProcessingStatusColorDict: Record<TradingDocumentPdfProcessingStatus, colorType> = {
  NEW: "transparentViolet",
  IN_PROGRESS: "orange",
  DONE: "green",
  ACCEPTED: "cyan",
  FAILED: "darkRed",
  REJECTED: "red",
};

const tradingDocumentTypeDict: Record<TradingDocumentType, string> = {
  INVOICE: "Faktura",
  RECEIPT: "Paragon",
};

const tradingDocumentTypeOptions: { id: TradingDocumentType; name: string }[] = Object.entries(
  tradingDocumentTypeDict,
).map(([id, name]) => ({
  id: id as TradingDocumentType,
  name,
}));

const paymentProviderOptions: { label: string; value: string }[] = [
  { label: "PAYU", value: "PAYU" },
  { label: "P24", value: "P24" },
  { label: "TPAY", value: "TPAY" },
  { label: "PAYNOW", value: "PAYNOW" },
  { label: "PAYPAL", value: "PAYPAL" },
  { label: "KLARNA", value: "KLARNA" },
];

const correctionReasonsMap: Record<
  CorrectionReasonField,
  Partial<Record<CorrectionReasonAction, string>>
> = {
  POSITION: {
    REMOVED: "Usunięto pozycję",
  },
  QUANTITY: {
    NEW: "Dodanie produktu",
    INCREMENT: "Zwiększenie liczby pozycji",
    DECREMENT: "Zmniejszenie liczby pozycji",
  },
  AMOUNT: {
    NEW: "Dodanie należności za fakturę",
    INCREMENT: "Zwiększenie należności za pozycję faktury",
    DECREMENT: "Zmniejszenie należności za pozycję faktury",
  },
  DISCOUNT: {
    NEW: "Przyznanie rabatu",
    INCREMENT: "Zwiększenie rabatu",
    DECREMENT: "Zmniejszenie rabatu",
  },
  VAT: {
    NEW: "Dodano stawkę VAT",
    INCREMENT: "Błędna stawka VAT, zwiększenie stawki VAT",
    DECREMENT: "Błędna stawka VAT, zmniejszenie stawki VAT",
  },
};

const tradingDocumentTypeToTabDict: Record<InvoiceType, string> = {
  ADVANCE: "advances",
  CORRECTION: "corrections",
  FINAL: "finals",
  PROFORMA: "proformas",
  PURCHASE: "purchases",
  SALES: "sales",
};

const tradingDocumentKindDict: Record<TradingDocumentKind, string> = {
  DROPSHIPPING: "dropshipping",
  OWN_ENTITY: "podmiot własny",
  RETAIL: "detaliczny",
  WHOLESALE: "hurtowy",
};

const tradingDocumentKindOptions: { label: string; value: TradingDocumentKind }[] = Object.entries(
  tradingDocumentKindDict,
).map(([value, label]) => ({
  label,
  value: value as TradingDocumentKind,
}));

const getPrintingStatusTagData = (
  status: TradingDocument["printingStatus"],
): { color: ColorPalette; label: string; value: string } => {
  switch (status) {
    case "FAILED":
      return { color: "danger500", label: "błąd drukowania", value: "FAILED" };
    case "NOT_STARTED":
      return { color: "success600", label: "gotowy do druku", value: "NOT_STARTED" };
    case "IN_PROGRESS":
      return { color: "deepPurple400", label: "drukuje się", value: "IN_PROGRESS" };
    case "FINISHED":
      return { color: "info500", label: "wydrukowano", value: "FINISHED" };
    default:
      const exhaustiveCheck = status;
      console.error(`Unhandled printing status: ${exhaustiveCheck}`);
      return { color: "neutralBlack100", label: EMPTY_VALUE, value: "" };
  }
};

const printingStatusOptions = generateDictBasedOnFactoryAndEnum(
  getPrintingStatusTagData,
  ReceiptPrintingStatus,
).map(({ value }) => ({
  color: value.color,
  label: value.label,
  value: value.value,
}));

export const tradingDocumentConstants = {
  correctionReasonsMap,
  expectedPaymentFormDict,
  invoicePaymentStatusDict,
  invoicePaymentStatusItems,
  invoicePaymentStatusOptions,
  invoiceProcessingStatusColorDict,
  invoiceProcessingStatusDict,
  invoiceReviewerStatusDict,
  invoiceStatusDict,
  invoiceTypeDict,
  invoiceTypeOptions,
  paymentMethodsOptions,
  paymentProviderOptions,
  printingStatusOptions,
  receiptPrintingStatusDict,
  receiptStatusDict,
  reviewStatusDict,
  taxProcedureDict,
  taxProcedureFilterOptions,
  tradingDocumentNotificationActionDict,
  tradingDocumentNotificationStatusDict,
  tradingDocumentPaymentKindForPurchaseInvoiceDict,
  tradingDocumentPaymentKindOptions,
  tradingDocumentPaymentModeShortcutsDict,
  tradingDocumentPaymentProviderDict,
  tradingDocumentPaymentProviderOptions,
  tradingDocumentPaymentTypeDict,
  tradingDocumentPaymentTypeOptions,
  tradingDocumentStatusDict,
  tradingDocumentStatusOptions,
  tradingDocumentStatusVariant,
  tradingDocumentTypeDict,
  tradingDocumentTypeOptions,
  viesStatusDict,
  tradingDocumentTypeToTabDict,
  tradingDocumentKindDict,
  tradingDocumentKindOptions,
};
